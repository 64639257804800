<template>
  <div class="page-6">
    <p>
      <h2 style="margin: 0 0 10px 0;">免费使用脚本教程（<span style="color: red;">包含DNF手游免费脚本</span>）</h2>
      <h3 style="margin: 0 0 10px 0;">飞天云用户Q群：3698384508</h3>
      <h3 style="margin: 0 0 10px 0;">蜗牛助手用户Q群：24681132</h3>
    </p>
    <p class=MsoNormal><b><span style='font-size:16.0pt;font-family:宋体;color:red'>第一步：点击网址</span></b><b>(<span
    lang=EN-US style='font-size:16.0pt;color:rgb(195, 0, 255)'><a href="/home">www.ftyunos.com</a></span>)</b><b><span
    style='font-size:16.0pt;font-family:宋体;color:red'>在浏览器中打开，或者</span></b>
    <b><span style='font-size:16.0pt;font-family:宋体;color:red'><a href="#" style="color:rgb(195, 0, 255)" @click="download()">
      点击下载</a>飞天云手机</span></b>
    </p>

    <p class=MsoNormal><b><span style='font-size:16.0pt;font-family:宋体;color:red'>第二步：在网站上下载</span></b><b><span
    lang=EN-US style='font-size:16.0pt;color:red'>APP</span></b></p>
    
    <p class=MsoNormal align=center style='text-align:center'><b><span lang=EN-US
    style='font-size:22.0pt;color:red'><img border=0 width=553 height=443 id="图片 1"
    src="@/assets/img/云手机免费用蜗牛教程/image001.jpg"></span></b></p>
    
    <p class=MsoNormal align=center style='text-align:center'><b><span lang=EN-US
    style='font-size:22.0pt;color:red'>&nbsp;</span></b></p>
    
    <p class=MsoNormal><b><span lang=EN-US style='font-size:16.0pt;color:red'>&nbsp;</span></b></p>
    
    <p class=MsoNormal><b><span lang=EN-US style='font-size:16.0pt;color:red'>&nbsp;</span></b></p>
    
    <p class=MsoNormal><b><span style='font-size:16.0pt;font-family:宋体;color:red'>第三步：注册一个账号</span></b><b><span
    lang=EN-US style='font-size:16.0pt;color:red'>(</span></b><b><span
    style='font-size:16.0pt;font-family:宋体;color:red'>新注册用户免费试用</span></b><b><span
    lang=EN-US style='font-size:16.0pt;color:red'>)</span></b></p>
    
    <p class=MsoNormal align=center style='text-align:center'><b><span lang=EN-US
    style='font-size:22.0pt;color:red'><img border=0 width=553 height=444 id="图片 2"
    src="@/assets/img/云手机免费用蜗牛教程/image002.jpg"></span></b></p>
    
    <p class=MsoNormal><b><span style='font-size:16.0pt;font-family:宋体;color:red'>第四步：到云手机应用市场里下载蜗牛助手</span></b></p>
    
    <p class=MsoNormal><b><span lang=EN-US style='font-size:22.0pt;color:red'><img
    border=0 width=553 height=470 id="图片 3" src="@/assets/img/云手机免费用蜗牛教程/image003.jpg"></span></b></p>
    
    <p class=MsoNormal><b><span style='font-size:16.0pt;font-family:宋体;color:red'>第五步：在飞天云手机( <a href="#" style="color:rgb(195, 0, 255)" @click="download()">点击下载</a> )内打开蜗牛助手是免费用的</span></b></p>
    
    <p class=MsoNormal align=center style='text-align:center'><b><span lang=EN-US
    style='font-size:22.0pt;color:red'><img border=0 width=553 height=470 id="图片 6"
    src="@/assets/img/云手机免费用蜗牛教程/image004.jpg"></span></b></p>
  </div>
</template>

<script>
import { request } from '@/network/request-download';
export default {
  name : "course",
  components : {
  },
  data() {
    return {
    }
  },
  methods: {
      download(){
        // 官网埋点
        request({
          url: `api/webSite/record/` + 1 + '/' + '百度广告',
          method: 'get',
        }).then(() => {
          request({
            url: `api/version/android/newApk/飞天云`,
            method: 'get',
          }).then(res => {
            if(res.code == 200) {
              window.open(res.data);
            }
          });
        })
      },
  }
}
</script>

<style scoped>
  .page-6{
    width: 100%;
    padding: 100px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .title{
    width: 600px;
  }

  .title-header{
    font-size: 2.5rem;
    text-align: center;
    margin-bottom: 25px;
  }

  .title-content{
    font-size: 1rem;
    color: #444;
    line-height: 1.3rem;
    
  }
 
</style>