var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"page-6"},[_c('p'),_vm._m(0),_c('h3',{staticStyle:{"margin":"0 0 10px 0"}},[_vm._v("飞天云用户Q群：3698384508")]),_c('h3',{staticStyle:{"margin":"0 0 10px 0"}},[_vm._v("蜗牛助手用户Q群：24681132")]),_c('p'),_c('p',{staticClass:"MsoNormal"},[_vm._m(1),_vm._m(2),_vm._m(3),_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","font-family":"宋体","color":"red"}},[_c('a',{staticStyle:{"color":"rgb(195, 0, 255)"},attrs:{"href":"#"},on:{"click":function($event){return _vm.download()}}},[_vm._v(" 点击下载")]),_vm._v("飞天云手机")])])]),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_vm._m(8),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_c('p',{staticClass:"MsoNormal"},[_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","font-family":"宋体","color":"red"}},[_vm._v("第五步：在飞天云手机( "),_c('a',{staticStyle:{"color":"rgb(195, 0, 255)"},attrs:{"href":"#"},on:{"click":function($event){return _vm.download()}}},[_vm._v("点击下载")]),_vm._v(" )内打开蜗牛助手是免费用的")])])]),_vm._m(13)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('h2',{staticStyle:{"margin":"0 0 10px 0"}},[_vm._v("免费使用脚本教程（"),_c('span',{staticStyle:{"color":"red"}},[_vm._v("包含DNF手游免费脚本")]),_vm._v("）")])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","font-family":"宋体","color":"red"}},[_vm._v("第一步：点击网址")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_vm._v("("),_c('span',{staticStyle:{"font-size":"16.0pt","color":"rgb(195, 0, 255)"},attrs:{"lang":"EN-US"}},[_c('a',{attrs:{"href":"/home"}},[_vm._v("www.ftyunos.com")])]),_vm._v(")")])
},function (){var _vm=this,_c=_vm._self._c;return _c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","font-family":"宋体","color":"red"}},[_vm._v("在浏览器中打开，或者")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","font-family":"宋体","color":"red"}},[_vm._v("第二步：在网站上下载")])]),_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","color":"red"},attrs:{"lang":"EN-US"}},[_vm._v("APP")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal",staticStyle:{"text-align":"center"},attrs:{"align":"center"}},[_c('b',[_c('span',{staticStyle:{"font-size":"22.0pt","color":"red"},attrs:{"lang":"EN-US"}},[_c('img',{attrs:{"border":"0","width":"553","height":"443","id":"图片 1","src":require("@/assets/img/云手机免费用蜗牛教程/image001.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal",staticStyle:{"text-align":"center"},attrs:{"align":"center"}},[_c('b',[_c('span',{staticStyle:{"font-size":"22.0pt","color":"red"},attrs:{"lang":"EN-US"}},[_vm._v(" ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","color":"red"},attrs:{"lang":"EN-US"}},[_vm._v(" ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","color":"red"},attrs:{"lang":"EN-US"}},[_vm._v(" ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","font-family":"宋体","color":"red"}},[_vm._v("第三步：注册一个账号")])]),_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","color":"red"},attrs:{"lang":"EN-US"}},[_vm._v("(")])]),_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","font-family":"宋体","color":"red"}},[_vm._v("新注册用户免费试用")])]),_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","color":"red"},attrs:{"lang":"EN-US"}},[_vm._v(")")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal",staticStyle:{"text-align":"center"},attrs:{"align":"center"}},[_c('b',[_c('span',{staticStyle:{"font-size":"22.0pt","color":"red"},attrs:{"lang":"EN-US"}},[_c('img',{attrs:{"border":"0","width":"553","height":"444","id":"图片 2","src":require("@/assets/img/云手机免费用蜗牛教程/image002.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('b',[_c('span',{staticStyle:{"font-size":"16.0pt","font-family":"宋体","color":"red"}},[_vm._v("第四步：到云手机应用市场里下载蜗牛助手")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal"},[_c('b',[_c('span',{staticStyle:{"font-size":"22.0pt","color":"red"},attrs:{"lang":"EN-US"}},[_c('img',{attrs:{"border":"0","width":"553","height":"470","id":"图片 3","src":require("@/assets/img/云手机免费用蜗牛教程/image003.jpg")}})])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticClass:"MsoNormal",staticStyle:{"text-align":"center"},attrs:{"align":"center"}},[_c('b',[_c('span',{staticStyle:{"font-size":"22.0pt","color":"red"},attrs:{"lang":"EN-US"}},[_c('img',{attrs:{"border":"0","width":"553","height":"470","id":"图片 6","src":require("@/assets/img/云手机免费用蜗牛教程/image004.jpg")}})])])])
}]

export { render, staticRenderFns }